<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card :loading="loading" flat>
					<v-card-title>
						{{ id ? "Editar objetivo" : "Cadastrar objetivo" }}
					</v-card-title>
					<v-col class="pl-10 pr-10 pb-5" cols="12" md="12">
						<v-row>
							<v-col cols="12" md="4">
								<v-select
									v-model="objective.CODEMP"
									:items="companies"
									item-text="DESEMP"
									item-value="CODEMP"
									label="Empresa"
									class="required"
									:loading="loadingCompany"
									@change="getUnits(true)"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="objective.CODUNI"
									:items="units"
									item-text="DESUNI"
									item-value="CODUNI"
									label="Unidade"
									class="required"
									:loading="loadingUnit"
									@change="getPerspectives(true)"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="objective.CODPLE"
									:items="strategicPlannings"
									item-text="DESPLE"
									item-value="CODPLE"
									class="required"
									label="Planejamento estrategico"
									:loading="loadingPlanning"
									@change="getPerspectives(true)"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="objective.CODPER"
									:items="perspectives"
									item-text="DESPER"
									item-value="CODPER"
									label="Perspectiva"
									:loading="loadingPerspective"
								></v-select>
							</v-col>
							<v-col cols="12" md="8">
								<v-text-field
									v-model="objective.DESOBJ"
									label="Nome"
									class="required"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row class="mt-5">
							<v-container>
								<v-btn
									color="primary"
									depressed
									tile
									class="mr-4"
									@click="save"
									:disabled="loading"
									>Salvar</v-btn
								>
								<v-btn
									color="primary"
									text
									class="mr-4"
									@click="$router.push('/objective/list')"
									:disabled="loading"
									>Voltar</v-btn
								>
							</v-container>
						</v-row>
					</v-col>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import ObjectiveService from "../../app/Services/ObjectiveService";
import StrategicPlanningService from "../../app/Services/StrategicPlanningService";
import PerspectiveService from "../../app/Services/PerspectiveService";
import CompanyService from "../../app/Services/CompanyService";
import UnitService from "../../app/Services/UnitService";

const objectiveService = ObjectiveService.build();
const strategicPlanningService = StrategicPlanningService.build();
const perspectiveService = PerspectiveService.build();
const companyService = CompanyService.build();
const unitService = UnitService.build();

export default {
	name: "ObjectiveForm",
	data: () => ({
		id: null,
		strategicPlannings: [],
		perspectives: [],
		objective: {},
		companies: [],
		units: [],
		loading: false,
		loadingPlanning: false,
		loadingCompany: false,
		loadingUnit: false,
		loadingPerspective: false,
		defaultValues: {},
	}),
	mounted() {
		this.defaultValues = JSON.parse(localStorage.getItem("default_filters"));
		const { id } = this.$route.params;

		if (id) {
			this.id = id;
		}

		this.fetch();
	},
	computed: {},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon,
			});
		},
		save() {
			if (!this.objective.DESOBJ) {
				this.toast("warning", "Informe o nome do objetivo para continuar");
				return;
			}

			if (!this.objective.CODEMP) {
				this.toast("warning", "Informe a empresa do objetivo para continuar");
				return;
			}

			if (!this.objective.CODUNI) {
				this.toast("warning", "Informe a unidade do objetivo para continuar");
				return;
			}

			if (!this.objective.CODPLE) {
				this.toast(
					"warning",
					"Informe o planejamento estrategico do objetivo para continuar"
				);
				return;
			}

			if (!this.objective.CODPER) {
				this.toast(
					"warning",
					"Informe a perspectiva do objetivo para continuar"
				);
				return;
			}

			this.loading = true;

			const rest = this.id
				? objectiveService.update({ id: this.id, ...this.objective })
				: objectiveService.create(this.objective);

			rest.then((response) => {
				if (!response) {
					return;
				}

				this.toast(
					"success",
					this.id
						? "Objetivo atualizado com sucesso!"
						: "Objetivo adicionado com sucesso!"
				);

				this.loading = false;

				this.$router.push("/objective/list");
			});
		},
		getObjective() {
			this.loading = true;

			return objectiveService.read({ id: this.id }).then((response) => {
				this.objective = response;
				this.loading = false;
			});
		},
		getCompanies() {
			this.loadingCompany = true;
			return companyService.search({}).then((response) => {
				this.companies = response;

				if (!this.objective.CODEMP && this.defaultValues.CODEMP) {
					this.objective.CODEMP = this.defaultValues.CODEMP;

					this.getUnits();
				}

				this.loadingCompany = false;
			});
		},
		getUnits(change) {
			const search = {};

			this.units = [];
			this.perspectives = [];

			if (change) {
				this.objective.CODUNI = "";
				this.objective.CODPER = "";
			}

			this.loadingUnit = true;

			if (this.objective.CODEMP) {
				search.company = this.objective.CODEMP;
			}

			return unitService.search(search).then((response) => {
				this.units = response;

				if (!this.objective.CODUNI && this.defaultValues.CODUNI) {
					this.objective.CODUNI = this.defaultValues.CODUNI;
				}

				this.loadingUnit = false;

				this.getPerspectives(false);
			});
		},
		getStrategicPlannings() {
			this.loadingPlanning = true;
			strategicPlanningService.search({}).then((response) => {
				this.strategicPlannings = response;

				if (!this.objective.CODPLE && this.defaultValues.CODPLE) {
					this.objective.CODPLE = this.defaultValues.CODPLE;
				}

				this.loadingPlanning = false;
			});
		},
		getPerspectives(change) {
			const search = {};

			this.perspectives = [];
			this.loadingPerspective = true;

			if (change) {
				this.objective.CODPER = "";
			}

			if (this.objective.CODEMP) {
				search.company = this.objective.CODEMP;
			}

			if (this.objective.CODUNI) {
				search.unit = this.objective.CODUNI;
			}

			if (this.objective.CODPLE) {
				search.strategicPlanning = this.objective.CODPLE;
			}

			perspectiveService.search(search).then((response) => {
				this.perspectives = response.data;
				this.loadingPerspective = false;
			});
		},
		fetch() {
			const funcs = [];

			if (this.id) {
				funcs.push(this.getObjective());
			}

			funcs.push(this.getStrategicPlannings());
			funcs.push(this.getCompanies());

			Promise.all(funcs)
				.then(() => {
					this.getUnits(false);
				})
				.finally(() => {});
		},
	},
};
</script>
